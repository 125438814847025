<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createClient">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">{{ $t("DetallesCliente") }}</h4>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group :label="$t('Nombre')" label-for="client-name">
                <b-form-input
                  v-model="name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('CIF')"
              rules="required"
            >
              <b-form-group :label="$t('CIF')" label-for="client-cif">
                <b-form-input
                  v-model="cif"
                  name="reference"
                  :placeholder="$t('CIF')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Direccion')"
              rules="required"
            >
              <b-form-group :label="$t('Direccion')" label-for="client-address">
                <b-form-input
                  v-model="address"
                  name="reference"
                  :placeholder="$t('Direccion')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('CodigoPostal')"
              rules="required"
            >
              <b-form-group
                :label="$t('CodigoPostal')"
                label-for="client-postalCode"
              >
                <b-form-input
                  v-model="postalCode"
                  name="reference"
                  :placeholder="$t('CodigoPostal')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Region')"
              rules="required"
            >
              <b-form-group :label="$t('Region')" label-for="client-region">
                <b-form-input
                  v-model="region"
                  name="reference"
                  :placeholder="$t('Region')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Pais')"
              rules="required"
            >
              <b-form-group :label="$t('Pais')" label-for="client-country">
                <b-form-input
                  v-model="country"
                  name="reference"
                  :placeholder="$t('Pais')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Ciudad')"
              rules="required"
            >
              <b-form-group :label="$t('Ciudad')" label-for="client-city">
                <b-form-input
                  v-model="city"
                  name="reference"
                  :placeholder="$t('Ciudad')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Telefono')"
              rules="required"
            >
              <b-form-group :label="$t('Telefono')" label-for="client-phone">
                <b-form-input
                  v-model="phone"
                  name="reference"
                  :placeholder="$t('Telefono')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              rules="email|required"
            >
              <b-form-group :label="$t('Email')" label-for="client-email">
                <b-form-input
                  v-model="emailInput"
                  name="reference"
                  :placeholder="$t('Email')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('Web')"
              rules="required"
            >
              <b-form-group :label="$t('Web')" label-for="client-web">
                <b-form-input
                  v-model="web"
                  name="reference"
                  :placeholder="$t('Web')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <b-form-group :label="$t('Imagen')" label-for="client-image">
              <ImageDropzone ref="image" :files="files" maxFiles="1" />
            </b-form-group>
          </b-col>
          <b-col cols="12 text-right">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ $t("Enviar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: "",
      cif: "",
      address: "",
      region: "",
      postalCode: "",
      city: "",
      emailInput: "",
      web: "",
      prone: "",
      country: "",
      files: [],
      required,
      email,
    };
  },
  computed: {
    ...mapGetters({
      client: "clients/getClient",
      currentCompany: "companies/getCurrentCompany",
    }),
  },
  methods: {
    ...mapActions({
      create: "clients/create",
      getClient: "clients/getClient",
    }),
    handleSubmit() {
      this.$refs.createClient.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ client: formData });
          }
        }
      });
    },
    createFormData() {
      const data = this.$refs.image.getFormData("image");

      data.append("name", this.name);
      data.append("cif", this.cif);
      data.append("address", this.address);
      data.append("postalCode", this.postalCode);
      data.append("region", this.region);
      data.append("country", this.country);
      data.append("city", this.city);
      data.append("phone", this.phone);
      data.append("email", this.emailInput);
      data.append("web", this.web);
      data.append("company_id", this.currentCompany.id);

      return data;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
